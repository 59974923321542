.feedback-area {
    min-height: 150px;
}

.feedback-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
}

.tooltip-inner {
    color: black;
    background-color: white;
    max-width: 300px;
}

.tooltip-arrow {
    opacity: 0;
}

@media screen and (max-width:768px) {
    .feedback-container {
        width: 95%;
        margin-top: 0px;
    }
}

.var-highlight{
    color: #C0AD60;
}
.string-highlight{
    color: rgba(253, 149, 90, 0.8);
}

.tagify__dropdown__item {
    color: black;
}
